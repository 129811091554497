import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import urljoin from 'url-join'
import $ from 'jquery';

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroLanding from "../components/HeroLanding"
import Recaptcha from 'react-recaptcha'

export class LandingPageTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isValidated: false,
      g_recaptcha_response: '',
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      message: ''
    }
    this.verifyCallback = this.verifyCallback.bind(this);
  }

  handleMainChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleLandingPageSubmit = e => {
    $.ajax({
      url: "https://71jmri3wtj.execute-api.us-west-2.amazonaws.com/production/",
      type: "POST",
      dataType: "JSON",
      data: JSON.stringify({
        ReplyTo: this.state.email,
        Name: this.state.firstName + " " + this.state.lastName,
        Company: this.state.company,
        Comments: this.state.message,
        Subject: "Signup - The New Normal",
        Gotcha: this.state.verifyAddress,
        GoogleRecaptchaResponse: this.state.g_recaptcha_response
      }),
      processData: false,
      success: function (data, textStatus, jQxhr) {
        window.location.href = urljoin(window.location.pathname, "thanks");
      },
      error: function (jqXhr, textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
    e.preventDefault();
  }

  verifyCallback = response => {
    this.setState({ g_recaptcha_response: response });
  };

  render() {
    const { seo, hero, formInfo } = this.props
    return (
      <div>
        <SEO title={seo.title} description={seo.description} />
        <HeroLanding data={hero} />
        <section className="section">
          <div className="container">
            <div className="contact">
              <p className="has-text-left"></p>
              <div className="columns">
                <div className="column is-2">
                </div>
                <div className="column is-8">
                  <p className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen">{formInfo.title}</p>
                  <p className="label">{formInfo.description}</p>
                  <form
                    name="contact"
                    id="contact"
                    onSubmit={this.handleLandingPageSubmit}
                  >
                    <div className="field">
                      <div className="columns">
                        <div className="column is-6">
                          <label htmlFor={'firstName'}>
                            First Name
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'text'}
                              name={'firstName'}
                              onChange={this.handleMainChange}
                              id={'firstName'}
                              required={true}
                              placeholder="Your First Name"
                            />
                          </div>
                        </div>
                        <div className="column is-6">
                          <label htmlFor={'lastName'}>
                            Last Name
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'text'}
                              name={'lastName'}
                              onChange={this.handleMainChange}
                              id={'lastName'}
                              required={true}
                              placeholder="Your Last Name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <div className="columns">
                        <div className="column is-6">
                          <label htmlFor={'company'}>
                            Company
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'text'}
                              name={'company'}
                              onChange={this.handleMainChange}
                              id={'company'}
                              required={true}
                              placeholder="Your Company Name"
                            />
                          </div>
                        </div>
                        <div className="column is-6">
                          <label htmlFor={'email'}>
                            Email Address
                          </label>
                          <div className="control">
                            <input
                              className="input"
                              type={'email'}
                              name={'email'}
                              onChange={this.handleMainChange}
                              id={'email'}
                              required={true}
                              placeholder="Your Email Address"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <Recaptcha
                        ref="recaptcha"
                        sitekey="6LdGF68UAAAAABk7L9D2Pvj51HM6rDoiuq-gOmzJ"
                        size="compact"
                        verifyCallback={this.verifyCallback}
                      />
                    </div>
                    <div className="field">
                      <button type="submit" style={{ background: "#53c5d1" }} >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div >
    )
  }
}

LandingPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  formInfo: PropTypes.object,
}

const LandingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  return (
    <Layout>
      <LandingPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        formInfo={frontmatter.formInfo}
      />
    </Layout>
  )
}

LandingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    })
  }),
}

export default LandingPage

export const pageQuery = graphql`
  query LandingPageTemplateByID($id: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: { templateKey: { eq: "landing-page" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 550, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        formInfo{
          title
          description
        }
      }
    }
  }
`
