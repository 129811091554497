import React, { Component } from 'react'
import Helmet from 'react-helmet'

class SEO extends Component {
	render() {
		const { title, description, titlePostfix, article_section, blogservice, homepage, blogonly } = this.props
		let data, data1;

		if (blogonly === true) {
			data1 = {
				"@context": "http://schema.org",
				"@type": "Article",
				"mainEntityOfPage": {
					"@type": "WebPage",
					"@id": "https://www.cypressdatadefense.com/blog/"
				},
				"headline": "C#/.NET/Core Training in Denver, CO – May 2019",
				"image": [
					"https://example.com/photos/1x1/photo.jpg"
				],
				"datePublished": "2015-02-05",
				"dateModified": "2015-02-05",
				"author": {
					"@type": "Person",
					"name": "Aaron Cure"
				},
				"publisher": {
					"@type": "Organization",
					"name": "cypressdatadefense",
					"logo": {
						"@type": "ImageObject",
						"url": "https://www.cypressdatadefense.com/static/logo-ac8b577fd0455e1e973fc1a60dc2e80b.png"
					}
				},
				"description": ""
			};
		} else {
			data1 = {};
		}

		if (blogservice === true) {
			data = {
				"@context": "https://schema.org",
				"@type": "BreadcrumbList",
				"itemListElement": [
					{
						"@type": "ListItem",
						"position": 1,
						"item": {
							"@id": "Home",
							"name": "https://www.cypressdatadefense.com/"
						}
					},
					{
						"@type": "ListItem",
						"position": 2,
						"item": {
							"@id": "security assessments",
							"name": "https://www.cypressdatadefense.com/security-assessments/"
						}
					},
					{
						"@type": "ListItem",
						"position": 3,
						"item": {
							"@id": "why security testing is important",
							"name": "https://www.cypressdatadefense.com/security-assessments/why-security-testing-is-important/"
						}
					},
					{
						"@type": "ListItem",
						"position": 4,
						"item": {
							"@id": "data-protection legislation and information security regulations",
							"name": "https://www.cypressdatadefense.com/security-assessments/why-security-testing-is-important/data-protection-legislation-and-information-security-regulations/"
						}
					}
				]
			};
		} else if (homepage === true) {
			data = {
				"@context": "https://schema.org",
				"@type": "Organization",
				"name": "cypressdatadefense",
				"url": "https://www.cypressdatadefense.com/",
				"logo": "https://www.cypressdatadefense.com/static/logo-ac8b577fd0455e1e973fc1a60dc2e80b.png"
			};
		} else {
			data = {};
		}

		const seo = {
			titlePostfix: titlePostfix || "%s | Cypress Data Defense",
			article_section: article_section || null,
		}
		return (
			<Helmet titleTemplate={seo.titlePostfix} htmlAttributes={{ lang: 'en_US' }}>
				<title>{`${title}`}</title>
				<meta
					name="description"
					content={`${description}`}
				/>
				{seo.article_section && <meta property="article:section" content={`${seo.article_section}`} />}
				<script type="application/ld+json">{`${JSON.stringify(data)}`}</script>
				<script type="application/ld+json">{`${JSON.stringify(data1)}`}</script>
			</Helmet>
		)
	}
}

export default SEO
